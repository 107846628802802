<template>
<job-posting-layout>
  <template #topContent>
    <div class="stepContent tw-w-full">
      <step-count :count="2"/>
      <step-title title="What level of experience are you looking to hire?" />
      <label class="err" v-if="isSelected">You have not selected level of experience</label>
        <div class="tw-flex tw-w-full  tw-flex-row tw-flex-wrap tw-py-5">
          <Loader v-if="loading" :loading="loading"/>
          <div v-if="!loading" class="tw-flex lg:tw-w-8/12 tw-w-full  tw-flex-row tw-flex-wrap tw-py-5">
            <div class="tw-mr-5 tw-mt-5 tw-cursor-pointer" :class="[level === selectedLevel ? 'selected-card': 'card']"
                 v-for="(level,index) in jobLevel" @click="selectedJobLevel(level)" :key="index">
              {{level}}
            </div>
            <div class="tw-mt-3 tw-flex align-center">
              <v-checkbox v-model="use_level" color="purple" />
              <span @click="use_level = !use_level">Add Experience Level to the Job Title  e.g. <b>Senior</b> Product Manager</span>
            </div>
            <div class="role-tips tw-mt-10  tw-w-full tw-p-5">
              {{tips}}
            </div>
          </div>
      </div>
    </div>
  </template>

  <template #bottomNav>
    <bottom-navbar :loading="buttonLoading" @click:back="handleBack" @click:next="handleUpdatePosting" :previous="true"/>
  </template>
</job-posting-layout>
</template>

<script>
import JobPostingLayout from "../layouts/JobPostingLayout";
import BottomNavbar from "../reuseables/BottomNavbar";
import StepCount from "../reuseables/StepCount";
import StepTitle from "../reuseables/StepTitle";
// import ItemCard from "../reuseables/ItemCard";
import {getJobById, updateJob} from "../../../services/api/APIService";
import Loader from "../../UIComponents/reusablesIcon/Loader";

export default {
  name: "StepTwo",
  components: {Loader,  StepTitle, StepCount, BottomNavbar, JobPostingLayout},
  data(){
    return{
      jobLevel:["Intern","Entry","Junior","Mid","Senior","Lead","Director","Executive"],
      selectedLevel : "",
      use_level:false,
      loading : false,
      tips:"Select a level of experience",
      defaultLevels : ["Intern","Entry","Junior","Mid","Senior","Lead","Director","Executive"],
      isSelected : false,
      buttonLoading : false
    }
  },
  watch:{
    selectedLevel : function (val) {
      if (val === 'Intern'){
        this.tips = 'Interns should have little or no experience in the field and should understand the fundamentals of the job.' +
            ' They usually have the ability to learn quick but might be new to a lot of concepts.'
      }
      else if(val === 'Entry'){
        this.tips = 'Entry level should have little or no experience work experience in the field and should understand the fundamentals of the job.' +
            'They are usually ready to apply their skills and gain industrial experience.'
      }
      else if(val === 'Junior'){
        this.tips = 'Junior Level should have about 1 - 2 years of experience in the field. They are growing their capacity on the job' +
            ' and might be able to embrace new challenges that accelerate growth.'
      }
      else if(val === 'Mid'){
        this.tips = 'Mid level staff should have a portfolio of projects they have directly or indirectly executed. ' +
            'They should be able to share from their experience in the field and foster the achievement of business ' +
            'objectives. They can be involved in administrative duties.'
      }
      else if(val === 'Senior'){
        this.tips = 'Senior Level should have more than 5 years of  experience in the field and should be very ' +
            'comfortable with advanced concepts on the job.'
      }
      else if(val === 'Lead'){
        this.tips = 'Team Leads should have more than 5 years of experience in the field and should be very comfortable ' +
            'with advanced concepts on the job. They should also demonstrate sound understanding of team building and human management.'
      }
      else if(val === 'Director'){
        this.tips = 'Directors should have more than 10 years experience in the field and should have a good understanding and' +
            ' advanced knowledge of the job, the ability to see the big picture and influence effectively at the board table.'
      }
      else if(val === 'Executive'){
        this.tips = 'Executives should have a clear direction in leading and steering the organization, they should be' +
            ' ambitious, demonstrate good listening ears, the ability to strategize and build trust among stake holders.'
      }},
  },
  methods:{
    handleBack(){
        this.$router.push({
          name: "Job-stepOne",
        })

    },
    selectedJobLevel(selectedLevel){
      this.selectedLevel = selectedLevel
    },
   async handleUpdatePosting(){
      this.buttonLoading = true
      let getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
      if (this.selectedLevel){
        this.isSelected = false
        if (getReturnedSelectedDetails.id){
          let data = {}
          data.experience_level = this.selectedLevel
          data.site_name = this.$siteName()
          data.use_level = this.use_level
          const response = await updateJob(getReturnedSelectedDetails.id, data)
          sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(response.data))
          getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
        }
        else {
          getReturnedSelectedDetails.experience_level = this.selectedLevel
          getReturnedSelectedDetails.use_level = this.use_level
          sessionStorage.setItem("returnedSelectedDetails", JSON.stringify(getReturnedSelectedDetails))
        }
        this.buttonLoading = false
        await this.$router.push({
          path: "3",
          name: "Job-stepThree",
        })
      }
      else {
        this.isSelected = true
        this.buttonLoading = false
      }
    },
   // async getExperienceLevel(){
   //    this.loading = true
   //    const getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
   //   await getExperienceLevels(getReturnedSelectedDetails.role).then(res =>{
   //      if (res.data.length === 0){
   //        this.jobLevel = this.defaultLevels
   //      }
   //      else {
   //        this.jobLevel = res.data
   //      }
   //      this.loading = false
   //    })
   //        .catch(err =>{
   //          console.log(err.response.data.detail)
   //          this.$displaySnackbar({message:err.response.data.detail, success:false})
   //        })
   //  },
  },
   async created() {

     const getReturnedSelectedDetails = JSON.parse(sessionStorage.getItem("returnedSelectedDetails"))
     if (getReturnedSelectedDetails.id){
       this.loading = true
       let data = {}
       data.id = getReturnedSelectedDetails.id
       data.site_name = this.$siteName()
      await getJobById(data).then(res =>{
         sessionStorage.setItem('returnedSelectedDetails', JSON.stringify(res.data))
         this.selectedLevel = res.data.experience_level
         this.use_level = res.data.use_level
        this.loading = false
       }).catch(err =>{
        this.loading = false
         this.$displaySnackbar({message:err.response.data.detail, success:false})
       })

    } else {
      this.selectedLevel = getReturnedSelectedDetails.experience_level
      this.use_level = getReturnedSelectedDetails.use_level
    }

  }
}
</script>

<style scoped lang="scss">
.selected-card-title-color{
  color: #008EEF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  min-width: 157px;
  min-height: 56px;
}
.card-title-color{
  color: #1E323F;
}
.card{
  border: 1px solid #D0DCE4;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #001343;
  min-width: 157px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 760px) {
    min-width: 100px;
  }
}

.card:hover{
  background: #F7FCFF;
  border: 1px solid #008EEF;
  border-radius: 8px;
}
.selected-card{
  background: #F7FCFF;
  color: #008EEF;
  border: 1px solid #008EEF;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  min-width: 157px;
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
span{
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #1E323F;
  cursor: pointer;
}

.desc{
  font-family: DM Sans,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #475661;
}

.role-tips{
  background: #F7FCFF;
  border: 1px solid #C5E7FF;
  box-sizing: border-box;
  border-radius: 12px;
  font-family: DM Sans !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #1E323F;
}
.err{
  color: red;
  font-size: 14px;
  margin-top: -1rem;
}
</style>